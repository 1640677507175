/*jshint esversion: 6 */

import CountrySelector from "../common/CountrySelector";

(function(){

    if (typeof previewerBodyAddLoginClass == 'function') {
        previewerBodyAddLoginClass();
    }
    if (typeof previewerCheckContentStatus == 'function') {
        previewerCheckContentStatus();
    }
    let b = document.querySelector('body');
    if(typeof b !== 'undefined'){
        b.classList.add('preview');
    }

    CountrySelector.setupCountrySelectorInterface();

})();
